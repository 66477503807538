import React from "react";

const pagesRoutes = [
  {
    path: "/dashboard",
    component: React.lazy(() => import("./dashboard/dashboard")),
  },
  {
    path: "/create-products",
    component: React.lazy(() =>
      import("./product/createProduct/createProducts")
    ),
  },
  {
    path: "/product-list",
    component: React.lazy(() => import("./product/productList/productList")),
  },
  {
    path: "/create-jobs",
    component: React.lazy(() => import("./jobs/createJobs")),
  },
  {
    path: "/create-demo",
    component: React.lazy(() => import("./jobs/createDemo.jsx")),
  },
  {
    path: "/schedule-jobs",
    component: React.lazy(() => import("./jobs/scheduleJobs")),
  },
  {
    path: "/not-schedule-jobs",
    component: React.lazy(() => import("./jobs/notSheduledJobs")),
  },
  {
    path: "/pending-jobs",
    component: React.lazy(() => import("./jobs/pendingJobs")),
  },
  {
    path: "/completed-jobs",
    component: React.lazy(() => import("./jobs/completedJobs")),
  },
  {
    path: "/pause-request-list",
    component: React.lazy(() => import("./jobs/pauseRequestList.jsx")),
  },
  {
    path: "/paused-list",
    component: React.lazy(() => import("./jobs/pausedList.jsx")),
  },
  {
    path: "/discontinued-job-list",
    component: React.lazy(() => import("./jobs/discontinuedJobs.jsx")),
  },

  {
    path: "/create-warranty",
    component: React.lazy(() => import("./warranty/createWarrenty")),
  },
  {
    path: "/create-quotation",
    component: React.lazy(() => import("./quotation/createQuotation")),
  },
  {
    path: "/create-spare-parts",
    component: React.lazy(() => import("./spareParts/createSpareParts")),
  },
  {
    path: "/spare-parts-list",
    component: React.lazy(() => import("./spareParts/sparePartsList")),
  },
  {
    path: "/update/spare-parts/:spareId",
    component: React.lazy(() => import("./spareParts/updateSpareParts")),
  },
  {
    path: "/update-product/:proId",
    component: React.lazy(() =>
      import(".//product/createProduct/updateProduct")
    ),
  },
  {
    path: "/warranty-list",
    component: React.lazy(() => import("./warranty/warrantyList")),
  },
  {
    path: "/expired-warranty-list",
    component: React.lazy(() => import("./warranty/expiredWarrantyList.jsx")),
  },
  {
    path: "/update-warranty/:warId",
    component: React.lazy(() => import("./warranty/updateWarranty")),
  },
  {
    path: "/update-job/:jobId",
    component: React.lazy(() => import("./jobs/udpateJobs")),
  },
  {
    path: "/register-technician",
    component: React.lazy(() => import("./technician/registerTechnician")),
  },
  {
    path: "/technician-list",
    component: React.lazy(() => import("./technician/techList")),
  },

  {
    path: "/update-technician/:techId",
    component: React.lazy(() => import("./technician/updateTechnician")),
  },
  {
    path: "/password",
    component: React.lazy(() => import("./general/password")),
  },
  {
    path: "/profile",
    component: React.lazy(() => import("./general/profile")),
  },
  {
    path: "/quotation-list",
    component: React.lazy(() => import("./quotation/quatationList")),
  },
  {
    path: "/update-quotation/:qId",
    component: React.lazy(() => import("./quotation/updateQuotation")),
  },
  {
    path: "/create-invoice",
    component: React.lazy(() => import("./invoice/createInvoice")),
  },
  {
    path: "/invoice-list",
    component: React.lazy(() => import("./invoice/invoiceList")),
  },
  {
    path: "/update-invoice/:id",
    component: React.lazy(() => import("./invoice//updateInvoice.jsx")),
  },
  {
    path: "/create-agreement",
    component: React.lazy(() => import("./agreement/createAgreement")),
  },
  {
    path: "/agreement-list",
    component: React.lazy(() => import("./agreement/agreementList")),
  },
  {
    path: "/visit-alert-list",
    component: React.lazy(() => import("./agreement/meetingAlertList")),
  },
  {
    path: "/agreement-expire-list",
    component: React.lazy(() => import("./agreement/agreementExpiredList")),
  },
  {
    path: "/agreement-expiry-list",
    component: React.lazy(() => import("./agreement/expiryList.jsx")),
  },
  {
    path: "/update-agreement/:agId",
    component: React.lazy(() => import("./agreement/updateAgreement.jsx")),
  },
  {
    path: "/create-template",
    component: React.lazy(() =>
      import("./termstemplate/createTemp.jsx")
    ),
  },
  {
    path: "/temp-list",
    component: React.lazy(() =>
      import("./termstemplate/tempList.jsx")
    ),
  },
  {
    path: "/update-temp/:tempId",
    component: React.lazy(() => import("./termstemplate/updateTemp.jsx")),
  },
  {
    path: "/create-email-template",
    component: React.lazy(() =>
      import("./termstemplate/emailTemplate/createEmailTemp.jsx")
    ),
  },
  {
    path: "/email-temp-list",
    component: React.lazy(() =>
      import("./termstemplate/emailTemplate/emailTempList.jsx")
    ),
  },
  {
    path: "/update-email-temp/:tempId",
    component: React.lazy(() => import("./termstemplate/emailTemplate/updateEmailTemp.jsx")),
  },
  {
    path: "/create-salesmen",
    component: React.lazy(() => import("./salesman/createSalesman.jsx")),
  },
  {
    path: "/salesmen-list",
    component: React.lazy(() => import("./salesman/salesmenList.jsx")),
  },
  {
    path: "/update-salesmen/:salesId",
    component: React.lazy(() => import("./salesman/updateSalesmen.jsx")),
  },
  {
    path: "/convert-agreement-job/:agId",
    component: React.lazy(() => import("./agreement/agreementToJob.jsx")),
  },

  {
    path: "/print-barcode",
    component: React.lazy(() => import("./product/barCodePrinter.jsx")),
  },
  {
    path: "/create-company",
    component: React.lazy(() => import("./company/createCompany.jsx")),
  },
  {
    path: "/company-list",
    component: React.lazy(() => import("./company/companyList.jsx")),
  },
  {
    path: "/create-expense-category",
    component: React.lazy(() => import("./expense/createCategory.jsx")),
  },
  {
    path: "/expense-category-list",
    component: React.lazy(() => import("./expense/categoryList.jsx")),
  },
  {
    path: "/create-expense",
    component: React.lazy(() => import("./expense/createExpense.jsx")),
  },
  {
    path: "/expense-list",
    component: React.lazy(() => import("./expense/expenseList.jsx")),
  },
  {
    path: "/admin-profile",
    component: React.lazy(() => import("./admin/adminProfile.jsx")),
  },
  {
    path: "/create-delivery",
    component: React.lazy(() => import("./delivery/createDelivery.jsx")),
  },
  {
    path: "/delivery-list",
    component: React.lazy(() => import("./delivery/deliveryList.jsx")),
  },
  {
    path: "/delivery-update/:id",
    component: React.lazy(() => import("./delivery/updateDelivery.jsx")),
  },
  {
    path: "/all-warranty-report",
    component: React.lazy(() => import("./reports/warrantyReport/allWarrantyReport.jsx")),
  },
  {
    path: "/all-ex-warranty-report",
    component: React.lazy(() => import("./reports/warrantyReport/expiredWarrantyReport.jsx")),
  },
  {
    path: "/all-follow-warranty-report",
    component: React.lazy(() => import("./reports/warrantyReport/followUpReport.jsx")),
  },
  {
    path: "/all-quote-report",
    component: React.lazy(() => import("./reports/quoteReport/allQuoteReport.jsx")),
  },
  {
    path: "/all-approved-quote-report",
    component: React.lazy(() => import("./reports/quoteReport/approvedQuoteReport.jsx")),
  },
  {
    path: "/all-invoice-report",
    component: React.lazy(() => import("./reports/invoiceReport.jsx")),
  },
  {
    path: "/all-pending-job-report",
    component: React.lazy(() => import("./reports/jobReport/pendingJobReport.jsx")),
  },
  {
    path: "/all-processing-job-report",
    component: React.lazy(() => import("./reports/jobReport/processingJobReprt.jsx")),
  },
  {
    path: "/all-completed-job-report",
    component: React.lazy(() => import("./reports/jobReport/completedJobReport.jsx")),
  },
  {
    path: "/all-agreement-report",
    component: React.lazy(() => import("./reports/agreementReport/allAgreementReport.jsx")),
  },
  {
    path: "/expired-agreement-report",
    component: React.lazy(() => import("./reports/agreementReport/expiredAgReport.jsx")),
  },
  {
    path: "/expired-agreement-report",
    component: React.lazy(() => import("./reports/agreementReport/expiredAgReport.jsx")),
  },
  {
    path: "/renewed-agreement-report",
    component: React.lazy(() => import("./reports/agreementReport/renewAgReport.jsx")),
  },
  {
    path: "/visit-agreement-report",
    component: React.lazy(() => import("./reports/agreementReport/visitWiseReport.jsx")),
  },
  {
    path: "/all-expense-report",
    component: React.lazy(() => import("./reports/expenseReport/allExpenseReport.jsx")),
  },
  {
    path: "/all-delivery-report",
    component: React.lazy(() => import("./reports/deliveryreport.jsx")),
  },





 
];

export default pagesRoutes;
