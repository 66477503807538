import React, { useState } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Box,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
// import { MatxLogo, MatxDivider } from "../../../components/index";
import { makeStyles } from "@material-ui/core/styles";
import history from "../../../history";
import clsx from "clsx";
import useAuth from "../../hooks/useAuth";
import { values } from "lodash-es";
import axios from "../../../axios";
import { notification, openNotificationWithIcon } from "antd";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    margin: "1rem",
  },
  cardLeft: {
    background: "#161c37 url(/assets/images/bg-3.png) no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 26,
      lineHeight: 1.3,
      fontWeight: 800,
    },
  },
  mainTitle: {
    fontSize: 18,
    lineHeight: 1.3,
    marginBottom: 24,
  },
  features: {
    "& .item": {
      position: "relative",
      marginBottom: 12,
      paddingLeft: 20,
      "&::after": {
        position: "absolute",
        content: '""',
        width: 4,
        height: 4,
        borderRadius: 4,
        left: 4,
        top: 7,
        backgroundColor: palette.error.main,
      },
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  socialButton: {
    width: "100%",
    "& img": {
      margin: "0 8px",
    },
  },
}));



const AdminLogin = () => {

  const validationSchema = Yup.object().shape({
    // phone: Yup.string().required("Phone is required"),
    // email: Yup.string().required("Email is required"),
  });
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phase, setPhase] = useState(0);

  const classes = useStyles();



  const openNotificationWithIcon = (message, type) => {
    notification[type]({
      message,
    });
  };

  const formSubmitHandler = async (data) => {
    if (phase == 0) {
      try {
        let obj1 = {
          email: data?.email,
          phone: data?.phone,
        };

        setIsLoading(true);
        const res = await axios.post(`/admin/reset-password/step1`, obj1);
        if (res) {
          reset();
          openNotificationWithIcon(res?.data?.message, "success");
          setPhase(1);
          setToken(res?.data?.data?.resetToken);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        openNotificationWithIcon(err?.response?.data?.message, "error");
      }
    } else if (phase == 1) {
      try {
        let obj2 = {
          otp: data?.otpCode,
          resetToken: token,
        };

        setIsLoading(true);
        const res = await axios.post(`/admin/reset-password/step2`, obj2);
        if (res) {
          reset();
          openNotificationWithIcon(res?.data?.message, "success");
          setPhase(2);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        openNotificationWithIcon(err?.response?.data?.message, "error");
      }
    } else {
      if (data?.newPassword != data?.confirmPassword) {
        openNotificationWithIcon("Password didn't matched", "error");
        return;
      }
      try {
        let obj3 = {
          resetToken: token,
          newPassword: data?.newPassword,
        };

        setIsLoading(true);
        const res = await axios.post(`/admin/reset-password/step3`, obj3);
        if (res) {
          reset();
          openNotificationWithIcon(res?.data?.message, "success");
         setPhase(0)
          history.push('/')
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        openNotificationWithIcon(err?.response?.data?.message, "error");
      }
    }
  };

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={6} md={6} sm={5} xs={12}>
            <div
              className={clsx({
                "py-8 px-14 h-full": true,
                [classes.cardLeft]: true,
              })}
            >
              <div className={classes.logo}>
                {/* <img height="45px" src="/Logo.png" alt="Logo" /> */}
              </div>
              <div className={classes.features}>
                <div className="item">Admin Password Reset</div>
              </div>
              <span className="flex-grow"></span>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {phase == 0 ? (
              <form
                className="px-4 py-6"
                onSubmit={handleSubmit(formSubmitHandler)}
              >
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 3 }}>
                      <InputLabel className="mb-2 text-black">PHONE</InputLabel>
                      <FormControl size="small" fullWidth variant="filled">
                        <OutlinedInput
                          type={"text"}
                          name="phone"
                          {...register("phone")}
                        />
                      </FormControl>
                      <p style={{ color: "red" }}>{errors.phone?.message}</p>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                      <InputLabel className="mb-2 text-black">EMAIL</InputLabel>
                      <FormControl size="small" fullWidth variant="filled">
                        <OutlinedInput
                          type={"text"}
                          name="email"
                          {...register("email")}
                        />
                      </FormControl>
                      <p style={{ color: "red" }}>{errors.email?.message}</p>
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  className="mb-4 mt-2 px-12"
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginRight: "20px" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Next"
                  )}
                </Button>
              </form>
            ) : (
              <>
                {phase == 1 ? (
                  <form
                    className="px-4 py-6"
                    onSubmit={handleSubmit(formSubmitHandler)}
                  >
                    <Grid container spacing={3}>
                      <Grid item sm={12} xs={12}>
                        <Box sx={{ mb: 3 }}>
                          <InputLabel className="mb-2 text-black">
                            OTP CODE
                          </InputLabel>
                          <FormControl size="small" fullWidth variant="filled">
                            <OutlinedInput
                              type={"number"}
                              name="otpCode"
                              {...register("otpCode")}
                            />
                          </FormControl>
                          <p style={{ color: "red" }}>
                            {errors.otpCode?.message}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>

                    <Button
                      className="mb-4 mt-2 px-12"
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginRight: "20px" }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "SUBMIT"
                      )}
                    </Button>
                  </form>
                ) : (
                  <form
                    className="px-4 py-6"
                    onSubmit={handleSubmit(formSubmitHandler)}
                  >
                    <Grid container spacing={3}>
                      <Grid item sm={12} xs={12}>
                        <Box sx={{ mb: 3 }}>
                          <InputLabel className="mb-2 text-black">
                            NEW PASSWORD
                          </InputLabel>
                          <FormControl size="small" fullWidth variant="filled">
                            <OutlinedInput
                              type={showNewPassword ? "text" : "password"}
                              name="newPassword"
                              {...register("newPassword")}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      setShowNewPassword(!showNewPassword)
                                    }
                                  >
                                    {showNewPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <p style={{ color: "red" }}>
                            {errors.newPassword?.message}
                          </p>
                        </Box>
                        <Box sx={{ mb: 3 }}>
                          <InputLabel className="mb-2 text-black">
                            CONFIRM PASSWORD
                          </InputLabel>
                          <FormControl size="small" fullWidth variant="filled">
                            <OutlinedInput
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              {...register("confirmPassword")}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                  >
                                    {showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <p style={{ color: "red" }}>
                            {errors.confirmPassword?.message}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>

                    <Button
                      className="mb-4 mt-2 px-12"
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginRight: "20px" }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "CHANGE"
                      )}
                    </Button>
                  </form>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default AdminLogin;
